<template>
  <div v-if="userConfig">
    <div v-if="!projects.length">
      No projects found
    </div>
    <div v-else>
      <div class="form-inline sub-mr-2 sub-mt2">
        <div>Select Project</div>
        <b-select v-model="selectedProject" @change="selectProject">
          <b-select-option v-for="project in projects" :key="project.id" :value="project">{{ project.name
            }}</b-select-option>
        </b-select>
      </div>
      <div v-if="selectedProject && !userConfig.projectsPaths[selectedProject.slug]">
        Enter path to install a project build on this computer:
        <div class="form-inline sub-mr-2 sub-mt-2">
          <b-form-input class="flex-grow-1" v-model="selectedProjectPath" placeholder="Path"></b-form-input>
          <button class="btn btn-primary mt-2" @click="savePath">Save</button>
        </div>
        <div class="form-inline sub-mr-2 sub-mt-2">
          <button class="btn btn-primary mt-2" @click="savePath(); getLatestVersion();"
            :disabled="!isValidPath(selectedProjectPath)">Get Latest Version</button>
        </div>
      </div>
      <div v-else>
        <!-- Install from Cloud -->
        <div>
          <div class="form-inline sub-mr-2 sub-mt-2">
            <div>Build Install Path:</div>
            <editable :text="selectedProjectPath" @change="(v) => { selectedProjectPath = v; savePath(); }">
              {{ selectedProjectPath }}
            </editable>
          </div>
          <div v-if="!showAdvanced">
            <div class="form-inline sub-mr-2 sub-mt-2">
              <button class="btn btn-primary" @click="getLatestVersion">Get Latest Version</button>
              <button class="btn btn-primary" @click="launchProject">Launch</button>
              <button class="btn btn-secondary" @click="showAdvanced = true">Show Advanced</button>
            </div>
          </div>
          <div v-else>
            <div class="mt-2">
              <button class="btn btn-secondary" @click="showAdvanced = false">Hide Advanced</button>
            </div>
            <div class="form-inline sub-mt-2 sub-mr-2">
              <div>Version</div>
              <b-select v-model="selectedVersion" @change="selectVersion">
                <b-select-option v-for="version in versions" :key="version.id" :value="version">{{ version.version
                  }}</b-select-option>
              </b-select>
              <button class="btn btn-primary" @click="getVersion();" :disabled="!selectedVersion">Get Major</button>
            </div>
            <div class="form-inline sub-mt-2 sub-mr-2">
              <div>Patch</div>
              <b-select v-model="selectedPatch" @change="selectPatch">
                <b-select-option v-for="patch in patches" :key="patch.id" :value="patch">{{ patch.patch
                  }}</b-select-option>
              </b-select>
              <button class="btn btn-primary" @click="getPatch();" :disabled="!selectedPatch">Get Patch</button>
            </div>
          </div>
        </div>
        <!-- Build from source -->
        <div>
          <div class="form-inline sub-mr-2 sub-mt-2">
            <div>UE Project Path (.uproject file):</div>
            <editable :text="selectedProjectSourcePath"
              @change="(v) => { selectedProjectSourcePath = v; saveSourcePath(); }">
              {{ selectedProjectSourcePath }}
            </editable>
          </div>
          <!-- Build from Source -->
          <div class="form-inline sub-mr-2 sub-mt-2" v-if="enginesPath && selectedProjectSourcePath">
            <!-- select engine -->
            <b-select v-model="selectedEngine">
              <b-select-option v-for="engine in engines" :key="engine.id" :value="engine">{{ engine.name
                }}</b-select-option>
            </b-select>
            <!-- select build command -->
            <b-select v-model="selectedBuildCommand">
              <b-select-option v-for="command in buildCommands" :key="command.id" :value="command">{{ command.name
                }}</b-select-option>
            </b-select>
            <button class="btn btn-primary" @click="buildFromSource">Build From Source</button>
            <button v-for="action in postBuildActions" :key="action.name"
              class="btn btn-primary" @click="runCommandInProjectFolder(action)">{{ action.name }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLog } from '@/services/log';
let log = getLog('sget-projects');
import { db } from '@/services/db';
import { checkFolderExists, isValidPath, getEnv } from './shivarun';

export default {
  components: {
    editable: () => import('@/components/editable.vue'),
  },
  data() {
    return {
      userConfig: {
        projectsPaths: {},
        projectsSourcePaths: {},
      },
      projects: [],
      selectedProject: null,
      selectedProjectPath: '',
      selectedProjectPathExists: false,
      selectedProjectSourcePath: '',
      versions: [],
      selectedVersion: null,
      patches: [],
      selectedPatch: null,

      engines: [],
      selectedEngine: null,
      enginesPath: '',
      buildCommands: [],
      selectedBuildCommand: null,
      postBuildActions: [],

      showAdvanced: false,
    };
  },
  watch: {
    "$store.account": () => {
      log.log('account', this.$store.account);
      this.initUserConfig();
    },
  },
  mounted() {
    log.log('mounted');
    this.initUserConfig();
  },
  methods: {
    isValidPath,
    async initUserConfig() {
      log.log('initUserConfig');
      await this.$bind("projects", db.collection('ShivaGetProjects').orderBy("rank", "asc"));
      if (this.projects.length) {
        this.selectedProject = this.projects[0];
        await this.selectProject();
      }
      if (this.$store.account) {
        await this.$bind("userConfig", db.collection('ShivaGetUserConfigs').doc(this.$store.account.uid));
        log.log('userConfig', this.userConfig);
        if (!this.userConfig || !this.userConfig.projectsPaths)
          db.collection('ShivaGetUserConfigs').doc(this.$store.account.uid).set({
            projectsPaths: {},
            projectsSourcePaths: {},
          }, { merge: true });
        else if (this.userConfig.settings?.ops)
          this.$debug.isOn = true;
        this.enginesPath = this.userConfig.enginesPath;
        this.listEngines();
        await this.selectProject();
      }
    },
    savePath() {
      log.log('savePath', this.selectedProjectPath);
      db.collection('ShivaGetUserConfigs').doc(this.$store.account.uid).set({
        projectsPaths: {
          [this.selectedProject.slug]: this.selectedProjectPath,
        }
      }, { merge: true });
    },
    saveSourcePath() {
      log.log('saveSourcePath', this.selectedProjectSourcePath);
      db.collection('ShivaGetUserConfigs').doc(this.$store.account.uid).set({
        projectsSourcePaths: {
          [this.selectedProject.slug]: this.selectedProjectSourcePath,
        }
      }, { merge: true });
    },
    async selectProject() {
      log.log('selectProject', this.selectedProject);
      this.selectedProjectPath = this.userConfig?.projectsPaths[this.selectedProject.slug];
      this.selectedProjectPathExists = await checkFolderExists(this.selectedProjectPath);
      this.selectedProjectSourcePath = this.userConfig?.projectsSourcePaths[this.selectedProject.slug];
      await this.$bind("versions", db.collection(`ShivaGetProjects/${this.selectedProject.id}/versions`));
      if (this.versions.length) {
        // get last item
        this.selectedVersion = this.versions[this.versions.length - 1];
        await this.selectVersion();
      }
      this.selectedEngine = this.selectProject.defaultEngine ? this.engines.find((v) => v.name == this.selectProject.defaultEngine) : this.engines[0];
    },
    async selectVersion() {
      log.log('selectVersion', this.selectedVersion);
      await this.$bind("patches", db.collection(`ShivaGetProjects/${this.selectedProject.id}/versions/${this.selectedVersion.id}/patches`));
      if (this.patches.length) {
        // get last item
        this.selectedPatch = this.patches[this.patches.length - 1];
        log.log('selectedPatch', this.selectedPatch);
      }
    },
    async selectPatch() {
      log.log('selectPatch', this.selectedPatch);
    },
    async getVersion(complete) {
      log.log('getVersion');
      this.$emit('run-command', 'ShivaGet.bat', [this.selectedProject.slug, this.selectedProjectPath], { windowsVerbatimArguments: true }, async () => {
        this.selectedProjectPathExists = await checkFolderExists(this.selectedProjectPath);
        if (complete) complete();
      });
    },
    async getPatch() {
      log.log('getPatch');
      if (!this.selectedPatch?.patch) {
        log.log('getPatch', 'No patch');
        return;
      }
      this.$emit('run-command', 'ShivaGetPatch.bat', [this.selectedProject.slug, this.selectedProjectPath, this.selectedPatch.patch], { windowsVerbatimArguments: true });
    },
    async getLatestVersion() {
      log.log('getLatestVersion');
      // Check if already has a major folder
      let env = await getEnv();
      if (!await checkFolderExists(`${env.shivaGetPath}\\Latest\\${this.selectedProject.slug}\\major`)
        || !this.selectedPatch?.patch) {
        // If not then GetVersion and GetPatch
        this.getVersion(() => {
          this.getPatch();
        })
      }
      else {
        // Else just Get Patch
        this.getPatch();
      }
    },
    async launchProject() {
      log.log('launchProject');
      let path = `${this.selectedProjectPath}\\${this.selectedProject.executablePath}`;
      this.$emit('run-command', path, [], { windowsVerbatimArguments: true });
    },
    async listEngines() {
      this.engines = ['UE_5.3_AS_2', 'UE_5.3'].map((name) => ({
        name,
        path: `${this.enginesPath}\\${name}`,
      }));
      this.selectedEngine = this.selectProject.defaultEngine ? this.engines.find((v) => v.name == this.selectProject.defaultEngine) : this.engines[0];
      // https://github.com/botman99/ue4-unreal-automation-tool?tab=readme-ov-file#Unreal-Automation-Tool
      this.buildCommands = [
        { name: 'BuildEditor', args: ['BuildEditor'] },
        { name: 'CleanBuildEditor', args: ['BuildEditor', '-clean'] },
        { name: 'BuildCookRun', args: ['BuildCookRun', '-cook', '-pak', '-stage', '-package'], stagingDir: "Releases" },
        { name: 'CleanBuildCookRun', args: ['BuildCookRun', '-clean', '-cook', '-pak', '-stage', '-package'], stagingDir: "Releases" },
        { name: 'BuildSingleMap', args: ['BuildCookRun', '-clean', '-cook', '-pak', '-stage', '-package'], stagingDir: "Releases\\SingleMap", maps: ["TestCombat1v1"] },
        { name: 'BuildGame', args: ['BuildGame'] },
        { name: 'BuildServer', args: ['BuildServer'] },
      ];
      this.postBuildActions = [
        { name: 'Compile AngelScript', cmd: 'CompileAS.bat' },
        { name: 'Launch', cmd: 'Releases\\Windows\\ShivaDemo.exe' },
      ];
      this.selectedBuildCommand = this.buildCommands[0];
    },
    getPath(filePath) {
      const lastSlashIndex = filePath.lastIndexOf("\\"); // Find the last slash
      return filePath.slice(0, lastSlashIndex); // Extract up to (and including) the slash
    },
    async runCommandInProjectFolder(action) {
      log.log('runCommandInProjectFolder', action);
      let cwd = this.getPath(this.selectedProjectSourcePath);
      this.$emit('run-command', `${cwd}\\${action.cmd}`, [], { cwd, windowsVerbatimArguments: true });
    },
    async buildFromSource() {
      log.log('buildFromSource', this.selectedEngine);
      let cmd = `Engine\\Build\\BatchFiles\\RunUAT.bat`;
      let cwd = this.selectedEngine.path;
      let extraArgs = [];
      // if stagingDir, append project folder path and add a new argument
      if (this.selectedBuildCommand.stagingDir) {
        let folderPath = this.getPath(this.selectedProjectSourcePath);
        let stagingDir = `-stagingdirectory="${folderPath}\\${this.selectedBuildCommand.stagingDir}"`;
        extraArgs.push(stagingDir);
      }
      if (this.selectedBuildCommand.maps) {
        let maps = `-map=${this.selectedBuildCommand.maps.join('+')}`;
        extraArgs.push(maps);
      }
      let params = [
        ...this.selectedBuildCommand.args,
        ...extraArgs,
        `-project="${this.selectedProjectSourcePath}"`,
        '-notools',
      ];
      this.$emit('run-command', `${cwd}\\${cmd}`, params, { cwd, windowsVerbatimArguments: true });
    },
  },
};
</script>